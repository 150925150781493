import React from 'react'
import PropTypes from 'prop-types';

function Button(props) {
    const { buttonName, handleSubmit, className, imageParam, disable } = props
    return (
        <button type="button"  disabled={ disable } className={ className } onClick={ handleSubmit }>{imageParam}{buttonName}</button>
    )
}

Button.propTypes = {
    buttonName: PropTypes.string,
    imageParam: PropTypes.func,
    handleSubmit: PropTypes.func,
    className: PropTypes.string
}

export default Button
